.shd-table {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  padding: 0rem 1.5rem 0 1.5rem;

  &--wrapper {
    // overflow: auto;
    position: relative;
    border-radius: 0.25rem;

    width: 100%;
    height: calc(100% - 2.625rem);
    max-height: calc(100% - 2.625rem);

    .os-scrollbar-vertical {
      width: 0.625rem !important;
      min-width: 0.625rem !important;
      max-width: 0.625rem !important;
      .os-scrollbar-handle {
        width: 0.375rem !important;
        min-width: 0.375rem !important;
        max-width: 0.375rem !important;
      }
    }
    .os-scrollbar-horizontal {
      height: 0.625rem !important;
      min-height: 0.625rem !important;
      max-height: 0.625rem !important;
      .os-scrollbar-handle {
        height: 0.375rem !important;
        min-height: 0.375rem !important;
        max-height: 0.375rem !important;
      }
    }

    .os-scrollbar-horizontal,
    .os-scrollbar-vertical {
      .os-scrollbar-handle {
        border-radius: 0.25rem;
        background-color: $gray-13;
      }
    }
  }

  &--thead {
    display: flex;
    height: 2.875rem;

    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  &--tr {
    display: flex;
    align-items: center;
    flex: 1;

    .shd-table--th,
    .shd-table--td {
      &.th--pinned,
      &.td--pinned {
        position: sticky !important;
        z-index: 1;

        &::after {
          content: '';
          width: 8px;
          height: 40px;
          display: inline-flex;
          opacity: 0.5;
          background: linear-gradient(
            90deg,
            #e2e6f5 0%,
            rgba(226, 230, 245, 0) 108.33%
          );

          position: absolute;
          right: 0;
        }

        .column-header-resizer,
        .column-cell-content--resizer {
          right: 0.5rem !important;
        }

        & + .shd-table--th,
        & + .shd-table--td {
          .column-header-content,
          .column-cell-content {
            border-left: none;
          }
        }
      }

      &.th--pinned {
        &::after {
          height: 46px;
        }
      }

      &:first-of-type {
        .column-header-content,
        .column-cell-content {
          padding: 0rem 1.25rem;
        }
      }

      &:first-of-type {
        .column-header-content {
          border-top-left-radius: 0.25rem;
        }
      }
      &:last-of-type {
        .column-header-content {
          border-top-right-radius: 0.25rem;
        }

        .column-header-content,
        .column-cell-content {
          border-right: 0.0625rem solid #f3f4f6;
        }
      }
    }

    &:hover {
      .shd-table--td {
        &:first-of-type {
          .column-cell-content {
            background-color: #f3f4f6;

            &.loading {
              background-color: #ffffff;
            }
          }
        }
        .column-cell-content {
          background-color: #f9fafb;
          &.loading {
            background-color: #ffffff;
          }
        }
      }
    }

    .column-header-content,
    .column-cell-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      width: 100%;
      height: 100%;

      padding: 0rem 1rem;
      transition: all 0.2s ease;

      border-top: 0.0625rem solid #f3f4f6;
      border-left: 0.0625rem solid #f3f4f6;

      &--checkbox {
        margin-right: 0.75rem;
      }
    }

    &:first-of-type {
      .column-cell-content {
        border-top: none;
      }
    }

    &:last-of-type {
      .column-header-content,
      .column-cell-content {
        border-bottom: 0.0625rem solid #f3f4f6;
      }
      .shd-table--td {
        &:first-of-type {
          .column-cell-content {
            border-bottom-left-radius: 0.25rem;
          }
        }
        &:last-of-type {
          .column-cell-content {
            border-bottom-right-radius: 0.25rem;
          }
        }
      }
    }
  }

  &--th {
    display: flex;
    align-items: center;

    position: relative;
    height: 100%;

    .column-header-content {
      width: 100%;
      height: 2.875rem;
      gap: 0.5rem;

      position: sticky;
      top: 0;

      display: flex;
      align-items: center;

      background-color: #ffffff;

      color: #6b7280;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem;

      transition: all 0.2s ease;

      &.loading {
        span:first-child {
          display: flex;
        }
      }

      &:hover {
        background-color: #f3f4f6;

        .column-header-content--sorting-icons {
          display: flex;
        }

        &.loading {
          background-color: #ffffff;
        }
      }

      &--name {
        flex: 1 1 0%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &--sorting-icons {
        width: 1.5rem;
        border-radius: 0.125rem;

        justify-content: center;
        align-items: center;
        flex-direction: column;

        display: none;

        &.active {
          display: flex;

          background-color: $white;
          svg {
            color: #d1d5db;
          }
        }

        .sorting-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 1rem;
          height: 0.875rem;

          cursor: pointer;

          transition: all 0.2s ease;

          svg {
            width: 100%;
            height: 100%;
            color: #6b7280;
            transition: all 0.2s ease;
          }

          &.sorted,
          &:hover {
            svg {
              color: $blue-15;
            }
          }
        }

        &:hover {
          background-color: $white;
          svg {
            color: #d1d5db;
          }
        }
      }
    }

    .column-header-resizer {
      width: 0.625rem;
      height: 2.875rem;

      display: flex;
      justify-content: flex-end;

      position: absolute;
      top: 0;

      cursor: col-resize;
      user-select: none;
      touch-action: none;

      transition: all 0.2s ease;
      z-index: 1;

      &--handle {
        width: 0.125rem;
        height: 2.875rem;
        background: #60a5fa;
      }

      &.ltr {
        right: 0;
      }

      &.isResizing {
        opacity: 1;
      }
    }
  }

  &--td {
    display: flex;
    align-items: center;

    position: relative;
    height: 100%;

    .column-cell-content {
      height: 2.5rem;
      position: relative;

      background-color: #ffffff;

      color: #1f2937;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;

      transition: all 0.2s ease;

      &--value {
        flex: 1 1 0%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &--resizer {
        display: none;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        width: 0.125rem;
        height: 2.5rem;
        background: #60a5fa;

        transition: all 0.2s ease;
      }

      &.loading {
        span:first-child {
          display: flex;
        }
      }
    }

    &.isResizing {
      .column-cell-content--resizer {
        display: block;
      }
    }
  }

  &--pagination {
    height: 2.5rem;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.25rem;

    position: absolute;
    left: 0;
    bottom: 0.0625rem;
    right: 0.0625rem;
    padding: 0 1.5rem;

    background-color: $white;
    border: 1px solid #d1d5db;
    box-shadow: 0px 0px 10px 0px rgba(4, 21, 76, 0.08);

    &__container {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .bs-select-box {
        width: 4.625rem;
      }
    }

    &__text {
      margin: 0;

      color: #1f2937;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }

    ul {
      margin: 0;
    }
  }
}

@media (hover: hover) {
  .column-header-resizer {
    opacity: 0;
  }
  *:hover > .column-header-resizer {
    opacity: 1;
  }
}

.hide-column-header--popover {
  width: 9.5rem;
  height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 0.125rem !important;
  padding: 0 !important;

  border-radius: 0.25rem !important;
  border: 0.0625rem solid #e5e7eb !important;

  /* dropdown */
  box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
    8px 16px 56px -24px rgba(156, 157, 161, 0.24) !important;

  .arrow {
    display: none !important;
  }

  .popover-body {
    width: 100%;
    .bs-popover-inner-content {
      padding: 0 !important;

      .hide-column-header--content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        cursor: pointer;

        padding: 0.5rem 1rem !important;

        svg {
          display: block;

          width: 0.875rem;
          height: 0.875rem;
          color: #1f2937;
        }

        p {
          color: #1f2937;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
          user-select: none;
        }
      }
    }
  }
}

.editable-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20px;

  .edit-text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .edit-button {
    display: none;
  }

  &:hover {
    display: flex;
    .edit-text {
      width: 69px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 8px;
    }
    .edit-button {
      display: inline;
      border: none;
      padding: 3px;
      cursor: pointer;
      background: none;

      svg {
        display: block;
      }

      &:hover {
        background-color: white;
        border-radius: 2px;
      }
    }
  }
}

.editable-textarea {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 80px;
  padding: 10px 16px;
  border: 1px solid #3b82f6;
  background: #f9fafb;
  z-index: 9999 !important;
  resize: both;
  width: 100%;
  color: #1f2937;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 13, 56, 0.12);
  max-width: 36.6875rem;
  max-height: 15.25rem;

  &:focus {
    outline: none;
  }
}

.editable-input,
.editable-input-number {
  display: flex !important;
  align-items: center !important;
  padding: 0px 16px !important;
  outline: none !important;
  border-radius: 0 !important;
  width: 12.625rem !important;
  height: 40px;
  border: 1px solid #3b82f6 !important;

  .bs-input {
    color: #1f2937;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus-visible,
  &:focus-within {
    outline: none !important;
    box-shadow: none !important;
  }
}

.editable-portal {
  // border: 1px solid #3b82f6;
  background: #fff;
}

.editable-input.error,
.editable-input-number.error,
.editable-textarea.error {
  border: 1px solid #f5222d !important;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.5) !important;
  .editable-input,
  .editable-input-number {
    border: 1px solid #f5222d !important;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.5) !important;
  }
}

.editable-portal.error {
  border: none;
}

.shd-table--td.editable-cell-hover:hover .column-cell-content {
  background-color: #f3f4f6;
}
