.unified-inbox-content {
  &--row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 56px;
    padding: 18px 24px;

    z-index: 4;

    background: #ffffff;
    border-bottom: 1px solid #eaedef;
    box-shadow: 1px 2px 4px #e5e7eb;

    .row-right,
    .row-left {
      display: flex;
      align-items: center;
      gap: 20px;
      .gap-20 {
        display: flex;
        gap: 1.25rem;
      }
      .active {
        position: relative;
        &__email-nav::before {
          content: '';
          position: absolute;
          top: 2.25rem;
          width: 100%;
          height: 4px;
          border-top-right-radius: 0.25rem;
          border-top-left-radius: 0.25rem;
          background-color: $blue-11;
        }
      }
      .transfer-select-btn {
        &:hover {
          border-color: $blue-11;
          background-color: $gray-19;
        }
      }
      .shd-button,
      .bs-select-box {
        &:hover {
          border-color: $blue-11;
          background-color: $gray-19;
        }
      }
      .sequence-report-select-btn,
      .date-filter-select-wrap {
        width: 12.25rem;
      }
    }

    &.filter-row {
      padding: 12px 24px;
      .row-right,
      .row-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        .gap-20 {
          display: flex;
          gap: 1.25rem;
        }
        .active {
          position: relative;
          &__email-nav::before {
            content: '';
            position: absolute;
            top: 2.25rem;
            width: 100%;
            height: 4px;
            border-top-right-radius: 0.25rem;
            border-top-left-radius: 0.25rem;
            background-color: $blue-11;
          }
        }
        .transfer-select-btn,
        .shd-button,
        .bs-select-box,
        .sequence-report-select-btn,
        .date-filter-select-wrap {
          width: 12.25rem;
        }
      }
    }

    .row-center {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      span {
        margin: 0;

        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $blue-15;
      }

      span.action-btn {
        color: $blue-11;
      }
      span.info-error {
        color: $red-12;
      }
    }

    .unified-inbox-action-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;
    }

    .unified-filter-icon-container {
      align-items: center;
      display: flex;
      gap: 0.5rem;
      color: $gray-15;
      &:hover {
        cursor: pointer;
        color: $blue-11;
      }
    }

    .unified-inbox-action-icon {
      padding-right: 0.25rem;
    }

    .filter-count {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .unified-filter-icon-container.with-cross {
      position: relative;
      align-items: center;
      padding-left: 0.25rem;
      display: flex;
      gap: 0.25rem;
      background-color: $blue-17;
      border: 0.0625rem solid $blue-13;
      color: $blue-11;
      border-radius: 0.25rem;
    }

    .unified-filter-icon-container.with-cross:hover {
      cursor: pointer;
      color: $blue-11;
    }

    .unified-filter-icon-container.with-cross .unified-inbox-action-cross-1 {
      border: 0.0625rem solid $blue-13;
      border-top-right-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem;
      border-top: none;
      border-bottom: none;
      padding: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .unified-filter-icon-container.with-cross .unified-inbox-action-cross {
      color: $gray-15;
    }
    .green-circle {
      position: absolute;
      width: 0.375rem;
      height: 0.375rem;
      top: 0.0625rem;
      left: 1rem;
      border: 0.0938rem solid $gray-1;
      border-radius: 50%;
      background-color: $green-13;
    }

    .unified-inbox-action-btn {
      width: 1.625rem;
      height: 1.625rem;

      display: flex;
      justify-content: center;
      align-items: center;

      color: #6b7280;
      border-radius: 4px;
      background-color: transparent;
      transition: all 0.2s ease;

      &:hover {
        color: $blue-11;
        background: #dbeafe;
      }
    }

    .refresh-unified-inbox {
      width: 18px;
      height: 18px;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        color: #6b7280;
        font-size: 18px;
        transition: all 0.2s ease;
      }

      &:hover {
        i {
          &::before {
            color: $blue-11;
          }
        }
      }
    }

    .unified-inbox-action-separator {
      width: 0.125rem;
      height: 1.25rem;

      background: #e5e7eb;
      border-radius: 2px;
    }

    .bs-select-box {
      width: auto;
      max-width: 210px;
      height: 32px;
      background: #f9fafb;
      border-radius: 4px;
      border-color: #e8eff5;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .checkbox-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #9ca3af;
    }

    .unified-inbox-sequence-btn {
      max-width: 196px;
      height: 32px;
      background: #f9fafb;
      border: 1px solid #e8eff5;
      border-radius: 4px;
      gap: 0.5rem;

      margin-right: 0 !important;

      &:hover,
      &.show {
        border-color: #1d4ed8;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #1f2937;

        width: 7.875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .unified-inbox-pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.25rem;

    &.sidebar-pagination {
      background-color: $white;
      padding: 0.75rem 1.5rem;

      width: 21.125rem;

      // position: absolute;
      // bottom: 0;
      // z-index: 3;
    }

    .unified-inbox-pagination-info {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #6b7280;
      margin: 0;
    }

    .unified-inbox-pagination-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
          color: #6b7280;
        }
        &.disabled {
          svg {
            color: #d1d5db;
          }
        }
      }
    }
  }

  .agency-client-unified-inbox {
    max-width: 196px;
    height: 32px;
    background: #f9fafb !important;
    border: 1px solid #e8eff5 !important;
    border-radius: 4px;
    gap: 0.5rem;
    &:hover,
    &.show {
      border-color: #1d4ed8 !important;
    }
  }

  &--container {
    display: flex;
    background-color: #f9fafb;
    .bs-input-affix-wrapper:focus,
    .bs-input-affix-wrapper:focus-within {
      border-color: #e5e7eb !important;
      border-width: 0px;
    }

    .unified-inbox-mail-list {
      width: 338px;
      min-width: 338px;
      height: 100%;

      overflow-x: hidden;
      overflow-y: auto;

      background-color: #f9fafb;

      scroll-behavior: smooth;
      box-shadow: 2px 1px 4px #e5e7eb;
      .unified-inbox-search {
        width: 100%;
        min-width: 12.1875rem;
        max-width: unset;
        height: 40px;
        &.bs-input-affix-wrapper:hover {
          border-color: #e5e7eb;
        }
        border: 1px solid #e5e7eb;
        background: #f9fafb;
        border-radius: 0rem;

        padding: 6px 16px;

        span {
          margin: 0 !important;

          &.input-icons-left {
            margin-right: 0.5rem !important;
          }
          &.input-icons-right {
            margin-left: 0.5rem !important;
          }
        }

        .bs-input-prefix,
        .bs-input-suffix {
          margin: 0 !important;
        }

        input {
          background: #f9fafb;

          &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #9ca3af;
          }
        }
        i {
          font-size: 1rem;
        }
      }
    }

    .unified-inbox-mail-content {
      width: calc(100% - 338px);
      height: 100%;
    }
  }

  .mail-item {
    width: 338px;
    height: 106px;

    display: flex;
    gap: 8px;
    justify-content: space-between;

    padding: 12px 24px;

    background: #f9fafb;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #e5e7eb;

    position: relative;
    z-index: 0;

    transition: all 0.2s ease;

    animation: fadeIn 0.5s linear;
    animation-fill-mode: both;

    // Set delay per List Item
    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        animation-delay: 0.06s * $i;
        background-color: $gray-1;
        border-color: $gray-13;
      }
    }
    .shd-pills--solid {
      background-color: $gray-1;
      border: 1px solid $gray-13;
    }

    &.unread {
      background: $gray-19;

      h2 {
        font-weight: 700;
      }
      h3 {
        font-weight: 600;
      }
    }

    &:hover {
      box-shadow: 0px 0px 4px #93c5fd;
      z-index: 2;

      .mail-item--left {
        .mail-item-avatar {
          display: none;
          transition: all 0.2s ease;
        }
        .mail-item-checkbox {
          display: inline-block;
          transition: all 0.2s ease;
        }
      }
    }

    &.active {
      background: $blue-17;
      border-color: $gray-13;
    }

    &.isSelected {
      .mail-item--left {
        .mail-item-avatar {
          display: none;
          transition: all 0.2s ease;
        }
        .mail-item-checkbox {
          display: block;
          transition: all 0.2s ease;
        }
      }
    }

    &--left {
      width: 1.5rem;
      min-width: 1.5rem;
      height: 2.25rem;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.2s ease;

      .mail-item-checkbox {
        display: none;
        transition: all 0.2s ease;

        width: 1rem;
        height: 1rem;
      }
    }

    &--right {
      width: calc(100% - 2rem);
      cursor: pointer;
    }

    h2 {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #1f2937;
      margin-bottom: 0.125rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .name-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .name {
        max-width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        display: inline-block;

        background: #1d4ed8;
      }

      .draft-label {
        color: #ef4444;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.125rem;
        margin: 0;
      }

      span.details-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
      }

      span.duration {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        color: #6b7280;
      }

      span.scheduledTime {
        color: #1f2937;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1rem;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;

        svg {
          width: 0.75rem;
          height: 0.75rem;
        }

        &.failedMail {
          color: $gray-15;
        }
      }

      span.failedEmailAlert {
        svg {
          width: 0.75rem;
          height: 0.75rem;
          color: #ef4444;
        }
      }
    }
    h3 {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #1f2937;
      margin-bottom: 0.125rem;

      width: 257px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #6b7280;
      margin: 0;
      padding-left: 3px;
      width: 255px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .unified-inbox-unSubScribed {
      display: flex;
      gap: 0.25rem;
    }
    .unsubscribed-message {
      border: 1px solid $red-11;
      background-color: $red-17;
      color: $gray-15;
      width: auto;
      height: 1.25rem;
      border-radius: 0.125rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 0.625rem;
      line-height: 1.125rem;
      font-weight: 500;
    }
    .finished-message {
      border: 1px solid $gray-13;
      background-color: $gray-19;
      color: $gray-15;
      width: auto;
      height: 1.25rem;
      border-radius: 0.125rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 0.625rem;
      line-height: 1.125rem;
      font-weight: 500;
      margin: 0;
    }
  }

  .unified-inbox-mail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    height: 70px;
    padding: 1.5rem;

    &--left {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    &--right {
      display: flex;
      align-items: center;
      gap: 1rem;

      .unsubscribed-message {
        border: 1px solid $red-11;
        background-color: $red-17;
        color: $gray-15;
        width: auto;
        height: 1.5rem;
        border-radius: 0.125rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-top: 0.6875rem;
        font-size: 0.75rem;
        line-height: 1.375rem;
        font-weight: 500;
      }
      .finished-message {
        border: 1px solid $gray-13;
        background-color: $gray-19;
        color: $gray-15;
        width: auto;
        height: 1.5rem;
        border-radius: 0.125rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.75rem;
        line-height: 1.375rem;
        font-weight: 500;
        margin: 0;
      }

      .mail-action-btn {
        width: 1.125rem;
        height: 1.125rem;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        transition: all 0.2s ease;

        svg {
          color: #6b7280;
        }

        &:hover {
          svg {
            color: #1f2937;
          }
        }
      }

      .bs-select-box {
        height: 24px;
        padding: 0 8px !important;
        border-radius: 4px;
        color: #1f2937 !important;
        background-color: rgb(236, 254, 255);
        border-color: rgb(165, 243, 252);
        &.email-category-renderer {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          &.positive-sentiment {
            border: 1px solid $green-20;
            background: $green-21;
          }
          &.positive-sentiment:hover {
            background: $green-11;
            border: 1px solid $green-13;
          }
          &.negative-sentiment {
            border: 1px solid $red-11 !important;
            background: $red-17 !important;
          }
          &.negative-sentiment:hover {
            background: $red-19;
            border: 1px solid $red-20;
          }

          &.neutral-sentiment {
            border: 1px solid $gray-23;
            background: $gray-16;
          }
          &.neutral-sentiment:hover {
            background: $gray-13;
            border: 1px solid $gray-11;
          }

          &.default-sentiment {
            border: 1px solid $gray-13;
            background: $gray-19;
          }
          &.default-sentiment:hover {
            background: $gray-16;
            border: 1px solid $gray-23;
          }
        }

        .bs-select-box-icon-wrapper {
          color: #4b5563 !important;
          justify-content: center;
        }
      }
    }

    h1 {
      margin: 0;
      font-weight: $font-weight-semibold;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #1f2937;
    }
  }

  .unified-inbox-mail-threads {
    overflow-y: auto;
    padding: 0 1.5rem 4px 1.5rem;
    position: relative;

    .mail-thread-header {
      .mail-thread-preview {
        width: calc(100% - 5rem);

        &.three-action-btn {
          width: calc(100% - 7.375rem);
        }

        &.four-action-btn {
          width: calc(100% - 9.75rem);
        }
        &.five-action-btn {
          width: calc(100% - 12.125rem);
        }

        div {
          &:first-of-type {
            position: relative;
          }
        }
      }
    }
    &.scheduled-email-thread {
      padding: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: $white;
    }
    .scheduled-email-thread-header {
      padding: 12px 24px;
      border-bottom: 1px solid #f3f4f6;
    }
    .scheduled-email-thread-content {
      padding: 16px 24px 24px 24px;

      .reply-forward-editor--footer {
        padding: 0;
        margin-top: 64px;
        justify-content: space-between;

        .scheduled-email-footer-buttons {
          gap: 12px;
        }
      }
    }

    .view-prospect-detail-modal {
      position: absolute;
      top: 2px;
      right: -1.5rem;

      width: 1rem;
      height: 1rem;

      display: none;
    }

    .mail-thread-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;

      position: absolute;
      right: 1.5rem;
      top: 1.125rem;

      div {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 26px;
        height: 26px;
        border-radius: 4px;

        transition: all 0.2s ease;

        i {
          font-size: 1.125rem;
          color: #6b7280;
        }
        svg {
          color: #6b7280;
        }

        &:hover {
          background: #dbeafe;
          i {
            color: $blue-11;
          }
          svg {
            color: $blue-11;
          }
        }

        &.disabled {
          opacity: 0.35;
          cursor: not-allowed;

          &:hover {
            background: #f3f4f6;
          }
        }
      }
    }

    .shd-accordion {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 0;
      padding-bottom: 1.5rem;
      padding-top: 0.25rem;

      .accordion__item {
        background: #ffffff;
        border: 1px solid #f3f4f6;
        box-shadow: 0px 0px 4px #e5e7eb;
        border-radius: 8px;
        position: relative;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .accordion__heading {
          display: flex;
          align-items: center;
          padding: 0;
          transition: all 0.2s ease;

          &:hover {
            background: #eff6ff;

            .view-prospect-detail-modal {
              display: inline-block;
            }
          }
        }

        .accordion__button {
          cursor: pointer;
          user-select: text;
          background-color: transparent;
          transition: none;
          color: #1f2937;
          padding: 0.75rem 1.5rem;
          width: 100%;
          text-align: left;
          border: none;

          &::before {
            display: none;
          }
        }

        .accordion__panel {
          padding: 20px 24px 24px 24px;

          border-top: 1px solid #f3f4f6;
        }
      }
    }
    .schedule-email-info {
      box-sizing: border-box;
      .shd-alert--icon svg {
        display: block;
      }
      .shd-alert--description {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        p {
          margin: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 0.5rem;

          color: $blue-15;
          font-size: 0.75rem;
          font-weight: $font-weight-medium;
          line-height: 1rem;

          span svg {
            display: block;
            color: #9ca3af;
          }
        }
        button {
          padding: 0 0.25rem;
          background-color: transparent;
          border: none;
          border-radius: 0.25rem;

          color: #9ca3af;
          font-size: 0.875rem;
          font-weight: $font-weight-medium;
          line-height: 1rem;

          outline: none;
          transition: all 0.2s ease;

          &:hover,
          &:focus-visible,
          &:focus-within,
          &:active {
            outline: none;
            color: #ef4444;
          }
        }
      }
    }
  }

  .reply-forward-editor {
    background: #f3f4f6;
    border: 1px solid #e5e7eb;
    box-shadow: 0px 0px 4px #e5e7eb;
    border-radius: 8px;
    margin-bottom: 20px;

    &--label {
      padding: 4px 12px;
      height: 26px;
      font-weight: 600;
      font-size: 10px;
      line-height: 18px;
      color: #6b7280;
    }

    &--label-draft {
      padding: 4px 12px;
      height: 26px;
      font-weight: 600;
      font-size: 10px;
      line-height: 18px;
      color: #ef4444;
    }

    &--container {
      background: #ffffff;
      border: 1px solid #f3f4f6;
      box-shadow: 0px 0px 4px #e5e7eb;
      border-radius: 8px;
      padding-bottom: 20px;
    }
    &--input {
      padding: 12px 24px 6px 24px;

      display: flex;
      align-items: center;
      gap: 8px;
      border-bottom: 1px solid #f3f4f6;

      .label {
        font-weight: 500;
        font-size: 12px;
        line-height: 1.5rem;
        color: #6b7280;
        align-self: flex-start;
      }

      .email-pills {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 0.5rem;
        width: 100%;
      }

      .email-input {
        border: none;
        outline: none;

        width: 100%;

        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #1f2937;

        &:disabled {
          background-color: transparent;
        }

        &:active,
        &:focus {
          outline: none;
        }
      }

      .cc-bcc-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #6b7280;
          cursor: pointer;
          transition: 0.2s ease;

          &:hover,
          &.active {
            color: #1d4ed8;
          }

          &.press-enter {
            width: 68px;
            color: #d1d5db;
            cursor: default;
            font-weight: 400;
            font-style: italic;

            &:hover,
            &.active {
              color: #d1d5db;
            }
          }
        }
      }
    }

    &--editor-wrapper {
      padding: 1rem 24px 20px 24px;

      .editor {
        height: 100%;
        min-height: 222px;
      }
    }

    &--footer {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      width: 100%;
      padding: 0 24px;
    }
  }
}

.no-selected-thread {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6b7280;
  }
}

.empty-unified-inbox {
  width: 100%;
  max-width: 737px;
  height: 100%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1f2937;
    margin-top: 24px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6b7280;
    margin-top: 8px;
    margin-bottom: 1rem;
  }
}

.unified-inbox-action-dropdown {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 0;
    margin: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-menu-custom {
    margin: 0;
    padding: 8px 0;
    flex-direction: column;

    a {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      color: #1f2937;

      border-bottom: none;
      transition: all 0.2s ease;
      &:hover {
        background: $blue-17;
      }
    }
  }
}

.unibox-mail-list-loader {
  display: flex;
  padding: 12px 24px;
  gap: 8px;
  border: 1px solid #f3f4f6;

  &--desc {
    display: flex;
    flex-direction: column;
    gap: 4px;

    span {
      height: 0.875rem;
    }

    div {
      display: flex;
      align-items: center;
      gap: 4px;

      &:first-of-type {
        justify-content: space-between;
      }
    }
  }
}

.unibox-mail-thread-loader-wrapper {
  padding: 1.5rem;

  span {
    height: 0.875rem;
  }

  .unibox-mail-thread-loader {
    background: #ffffff;
    border: 1px solid #f3f4f6;
    box-shadow: 0px 0px 4px #e5e7eb;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;

    &--header {
      display: flex;

      gap: 0.75rem;
      padding: 0.75rem 1.5rem;
      border-bottom: 1px solid #f3f4f6;
    }
    &--desc {
      padding: 1.25rem 1.5rem;

      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.unified-inbox-mail-list-blocked {
  width: 338px;
  height: 212px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7) 14.35%,
    rgba(255, 255, 255, 0.95) 27.8%
  );
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #eaedef;
  box-shadow: 2px 1px 4px #e5e7eb;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #1f2937;

    width: 250px;
    height: 60px;

    span {
      color: $blue-11;
      text-decoration: underline;
      text-underline-offset: 0.125rem;
      cursor: pointer;
    }
  }
}

.prospect--applied-filters {
  border-bottom: 1px solid rgb(209, 213, 219);
  padding: 1rem;
  .filter-tab-container {
    margin-bottom: 0;
  }
}

.activity-modal {
  .modal-header {
    border-bottom: none;
    align-items: flex-start;
  }
  .modal-body {
    padding: 0 !important;
    overflow: hidden;
    max-height: calc(90vh - 5.1rem) !important;
  }
  .modal-dialog {
    width: 45rem;
    max-width: 45rem;
  }
  .modal-content {
    height: 35rem;
    border-radius: 0.5rem !important;
  }
}

.activity-tabs {
  .bs-tabs-tabpane {
    &.unibox-email-link-clicked {
      .log-activity {
        &::after {
          height: calc(100% - 3.25rem);
        }
      }
      .icon-log-container {
        height: auto;
        align-items: flex-start;
        .icon-container-wrapper {
          height: 2rem;
        }
      }
    }
  }
  .bs-tabs-nav {
    height: 3rem;
    padding: 0 1.5rem;

    &::before {
      border-bottom-color: $gray-16;
    }
  }
  .bs-tabs-tab {
    &.bs-tabs-tab-active {
      background-color: $blue-17;
      .tab-wrapper {
        .label-wrapper {
          color: $blue-11;
          font-weight: $font-weight-medium;
        }
        .counter-wrapper {
          color: $blue-11;
        }
      }
    }
    .tab-wrapper {
      .label-wrapper {
        color: $blue-15;
      }
      .icon-wrapper img {
        width: 1.5rem;
        height: 1.5rem;
      }
      .counter-wrapper {
        color: $gray-12;
        font-weight: $font-weight-medium;
      }
    }
  }
  .bs-tabs-ink-bar {
    border-radius: 0.25rem;
    background-color: $blue-11;
  }
  .bs-tabs-content-holder {
    height: 100%;
    padding-left: 1.5rem;
    .bs-tabs-content {
      height: 25.5rem;
      overflow-y: auto;

      .log-activity {
        .day-text {
          background-color: $gray-16;
          color: $blue-15;
          border-radius: 0.125rem;
          font-weight: $font-weight-medium;
          font-size: 0.75rem;
        }
      }
    }
  }
}

.activity-label {
  color: $blue-15;
}

.activity-log {
  color: $gray-15;
  img {
    margin-top: -0.125rem;
  }
}

.activity-link {
  width: max-content;
  max-width: 38rem;
  .link-wrapper {
    max-width: 38rem;
    height: 1.75rem;

    padding: 0.25rem 0.625rem;
    margin-top: 0.25rem;

    background: $gray-19;

    border: 0.0625rem solid $gray-13;
    border-radius: 0.25rem;
    box-sizing: border-box;

    color: $gray-15;
    font-weight: $font-weight-medium;
    text-decoration: none;

    img {
      margin-right: 0.5rem;
    }
  }

  .tooltiptext {
    max-width: 38rem !important;
    font-size: 0.75rem;
    font-weight: $font-weight-medium;
  }
}

// Keyframe animation
@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 100px;
  }
  75% {
    opacity: 0.5;
    top: 0px;
  }
  100% {
    opacity: 1;
  }
}

.email-category {
  display: flex;
  align-items: center;

  padding: 2px 5px;
  gap: 2px;

  height: 20px;

  border-radius: 2px;
  margin-left: 4px;
  &.positive-sentiment {
    border: 1px solid $green-13;
    background: $green-21;
  }
  &.positive-sentiment:hover {
    background: $green-11;
    border: 1px solid $green-20;
  }
  &.negative-sentiment {
    border: 1px solid $red-11;
    background: $red-17;
  }
  &.negative-sentiment:hover {
    background: $red-19;
    border: 1px solid $red-20;
  }

  &.neutral-sentiment {
    border: 1px solid $gray-23;
    background: $gray-16;
  }
  &.neutral-sentiment:hover {
    background: $gray-13;
    border: 1px solid $gray-11;
  }

  &.default-sentiment {
    border: 1px solid $gray-13;
    background: $gray-19;
  }
  &.default-sentiment:hover {
    background: $gray-16;
    border: 1px solid $gray-23;
  }

  &.disabled,
  &.disabled:hover {
    background: transparent;
    border: none;
  }

  svg,
  img {
    width: 12px;
    height: 12px;
  }

  span {
    height: 16px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }
}

.unified-inbox-filter-select {
  width: fit-content !important;
  padding: 3px 0px !important;

  .transfer-container {
    width: fit-content !important;
    background: #ffffff;
    border: 1px solid #e5e7eb;

    box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
      8px 16px 56px -24px rgba(156, 157, 161, 0.24);
    border-radius: 4px;
  }
  .bs-select-box {
    width: auto;
    max-width: 210px;
    height: 32px;
    background: #f9fafb;
    border-radius: 4px;
    border-color: #e8eff5;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

.no-selected-email {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;

  // height: 100%;
  height: calc(100% - 60px);

  img {
    height: 80px;
  }

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1f2937;
  }

  p {
    width: 249px;
    margin: 0;
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6b7280;
  }
}

.delete-conversation-confirmation-modal {
  .modal-dialog {
    .modal-header {
      padding: 0 1.5rem !important;
    }
    .confirmation-modal-v3--title {
      h2 {
        margin-left: 0.5rem !important;
      }
    }
    .align-items-center {
      align-items: start !important;
    }
  }
  &.confirmation-modal-v3 .modal-dialog .modal-content .bs-modal-body {
    padding: 1.5rem !important;
  }
  &.confirmation-modal-v3 .modal-dialog .modal-footer {
    padding: 1.25rem 0 0 0;
    margin: 0 1.5rem 1.25rem 1.5rem !important;
    min-height: 3.375rem;
    max-height: 3.375rem;
  }

  &.confirmation-modal-v3
    .modal-dialog
    .modal-footer
    .bs-modal-footer-action-buttons
    button:last-of-type {
    margin-left: 1rem !important;
  }

  p {
    margin: 0 !important;
    font-size: 0.875rem !important;
  }

  span {
    font-family: Inter;
    font-weight: $font-weight-normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $blue-15 !important;
  }

  .action-btn-delete.btn-solid-primary {
    background-color: #b91c1c;
    border-color: #b91c1c;
    border-radius: 4px;
    height: 2rem;
    min-width: 77px !important;
    padding: 0.375rem 1rem;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;

    &:hover,
    &:focus,
    &:active,
    &.btn-solid-primary:not(:disabled):not(.disabled):active {
      border-color: #991b1b;
      background-color: #991b1b;
    }
  }
  .action-btn-cancel.btn-ghost-outlined {
    color: #6b7280;
    border-radius: 4px;
    border: 1px solid #e5e7eb !important;
    height: 2rem;
    width: 79px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.375rem 1rem;

    &:hover,
    &:focus,
    &:active,
    &.btn-ghost-outlined:not(:disabled):not(.disabled):active {
      color: #6b7280;
      background-color: #f3f4f6;
    }
  }

  .bs-select-box {
    height: 32px;
    background: #f9fafb;
    border-radius: 4px;
    border-color: #e8eff5;
  }
}

.br-7 {
  border-radius: 0.4375rem;
}

.unified-inbox-filter-assign {
  .transfer-container {
    .transfer-body {
      .transfer-content {
        height: 16.3rem;
        overflow-y: scroll;
      }
    }
  }
}

.schedule-confirmation-popup {
  .modal-footer {
    padding: 1.25rem 0 0 0 !important;
    min-height: 54px;
    max-height: 54px;
  }
}

.unified-inbox-filter-bar-tooltip {
  .tooltip-inner {
    max-width: 32rem !important;
  }
}

.external-email-popover-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;

  transition: all 0.2s ease;

  svg {
    color: $gray-15;
  }

  &:hover {
    svg {
      color: $blue-11;
    }
  }
}

.external-email-popover {
  z-index: 3 !important;
  max-width: 20.375rem !important;
  &.mail-thread-popover {
    z-index: 1060 !important;
  }
  .bs-popover-inner-content {
    padding: 0rem !important;

    .icon svg {
      color: $gray-15 !important;
    }
  }
  .external-email-popover-wrapper {
    padding: 0.5rem !important;
    display: flex;
    justify-content: center;
    gap: 0.5rem !important;
  }
  .external-email-popover-content {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    width: 17.75rem !important;

    p {
      margin: 0;

      color: $blue-15;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1rem;
    }
    .title {
      color: $gray-15;
    }
    a {
      text-decoration: underline;
    }
  }
}

.gap-1 {
  gap: 0.25rem;
}

.email-x-button-container {
  position: relative !important;
  display: inline-block !important;

  .email-x-icon {
    height: 2rem;
    width: 2rem;
    border-radius: 0.25rem;
    cursor: pointer;
    svg {
      color: $gray-15;
    }
    &.selected {
      color: black !important;
      cursor: default;
      svg {
        color: $blue-11;
      }
    }
  }
}

.dropdown-menu {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 13, 56, 0.12);
  min-width: 12.5rem;
  white-space: nowrap;
  width: 12.5rem;
  transform-origin: top left;
  gap: 0.25rem;
  height: 5.25rem;
  border: none;
  li {
    transition: all 0.2 ease;
    &:hover {
      background-color: $blue-17;
    }
  }
}

.unified-inbox-menu-item {
  width: 100% !important;
  cursor: pointer;
  padding: 6px 12px;
}

.menu-text {
  font-size: 0.875rem;
  margin: 0;
  line-height: 1.25rem !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  color: #1f2937;
}

.email-x-button-container .dropdown-menu {
  margin-top: 0.25rem;
  left: auto;
  right: 0;
}

.block-confirmation-modal {
  .modal-dialog {
    .modal-content {
      min-height: auto !important;
    }
    .modal-header {
      padding: 0 1.5rem !important;
      min-height: auto;
    }
    .confirmation-modal-v3--title {
      h2 {
        margin-left: 0 !important;
      }
      svg {
        color: #1f2937 !important;
      }
    }
  }

  &.confirmation-modal-v3 .modal-dialog .modal-content .bs-modal-body {
    padding: 1.5rem !important;
  }
  &.confirmation-modal-v3 .modal-dialog .modal-footer {
    padding: 1.25rem 0 0 0;
    margin: 0 1.5rem 1.25rem 1.5rem !important;
    min-height: 3.375rem;
    max-height: 3.375rem;
  }

  &.confirmation-modal-v3
    .modal-dialog
    .modal-footer
    .bs-modal-footer-action-buttons
    button:last-of-type {
    margin-left: 1rem !important;
  }

  p {
    margin-bottom: 1rem !important;
    font-size: 0.875rem !important;
  }
  span {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #1f2937 !important;
  }

  .action-btn-yes.btn-solid-primary {
    background-color: $blue-11;
    border-color: $blue-11;
    border-radius: 4px;
    height: 2rem;
    min-width: 77px !important;
    padding: 0.375rem 1rem;
    font-family: Inter;
    font-size: $btn-custom-font-size;
    font-style: normal;
    font-weight: $font-weight-medium;
    line-height: 1rem;

    .icon-left {
      color: $white;
    }

    &:hover {
      border-color: $blue-20;
      background-color: $blue-20;
    }
  }
  .action-btn-cancel.btn-ghost-outlined {
    color: #6b7280;
    border-radius: 4px;
    border: 1px solid $gray-13 !important;
    height: 2rem;
    width: 79px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: $font-weight-medium;
    font-size: $btn-custom-font-size;
    line-height: 1rem;
    padding: 0.375rem 1rem;

    &:hover,
    &:focus,
    &:active,
    &.btn-ghost-outlined:not(:disabled):not(.disabled):active {
      color: #6b7280;
      background-color: #f3f4f6;
    }
  }

  .bs-select-box {
    height: 32px;
    background: #f9fafb;
    border-radius: 4px;
    border-color: #e8eff5;
  }
}

.block-alert-modal--title {
  display: flex;
  align-items: start !important;
  gap: 0.5rem;
  &__icon {
    color: $red-12;
    line-height: 1.5rem !important;
    font-size: 1.5rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
  h2 {
    line-height: 1.5rem !important;
    align-items: center !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    font-size: 1rem;
    font-weight: 600;
    color: $blue-15;
  }
}

.mobile-app-banner.alert.alert-primary-2 .alert-message-text {
  color: #1f2937 !important;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-left: 6px;
}

.mobile-app-banner {
  background: #ede9fe !important;
  margin-bottom: 0 !important;
  padding: 8px !important;
  align-items: center;
  border: none;
  border-radius: 0;

  div {
    width: 100%;
    justify-content: center;
    height: 16px;
  }

  .upgrade-btn {
    background: transparent !important;
    color: $blue-11 !important;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .mobile-app-banner-close-btn {
    margin-right: 16px;
  }
}

.shd-modal.qr-code-modal .modal-content {
  border: none !important;
}

.qr-code-modal {
  .modal-dialog {
    max-width: 18.25rem !important;
  }

  .shd-modal--header {
    position: relative;
    padding: 1rem 1rem 0px 1rem !important;
  }

  .shd-modal--close-btn svg {
    position: absolute;
    right: 0;
    top: 0;
    width: 1rem !important;
    height: 1rem !important;
    color: #6b7280;
    margin-top: 0.5625rem;
    margin-right: 0.5625rem;
  }

  .modal-title {
    display: flex;
    align-items: center;
    color: #1f2937;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;

    .badge-new {
      background-color: #f59e0b;
      color: #fff;
      font-size: 0.625rem;
      line-height: 0.75rem;
      font-weight: 500;
      padding: 0.0625rem 0.1875rem;
      border-radius: 0.1875rem;
      text-align: center;
      margin-left: 0.25rem;
    }
  }

  .shd-modal--body {
    padding: 0px 1rem !important;
    margin-top: 0.5rem;
    p {
      margin: 0;
      color: #1f2937;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
    }
  }

  .qr-code-container {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0rem;
    height: 9.125rem;

    .qr-code-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .shd-modal--divider {
    display: none !important;
  }
}
