/**
App CSS Guide

*.  Home
*.  Main Navigation
*.  Settings
*.  Authenticate User (Login/Signup/Forgot)
*.  Sequence
**. Misc
*/

// *. Home ///////////////////////////////////
@import './../../components/home/home';
// *. Home ///////////////////////////////////

// *. Main Navigation ///////////////////////////////////
@import './../../assets/css/scss/mainNavigation';
// *. Main Navigation ///////////////////////////////////

// *. Settings ///////////////////////////////////
@import './../../components/settings/settings';
// *. Settings ///////////////////////////////////

// *. Authenticate User (Login/Signup/Forgot) ///////////////////////////////////
@import '../../components/auth/auth';
// *. Authenticate User (Login/Signup/Forgot) ///////////////////////////////////

// *. Sequence ///////////////////////////////////
@import '../../components/sequence/sequence';
// *. Sequence ///////////////////////////////////

// *. Prospect ///////////////////////////////////
@import '../../components/prospect/prospect';
// *. Prospect ///////////////////////////////////

// *. Email-Accounts ///////////////////////////////////
@import '../../components/email-accounts/email-accounts';
// *. Email-Accounts ///////////////////////////////////

// *. Domains ///////////////////////////////////
@import '../../components/domains/domains';
// *. Domains ///////////////////////////////////

// *. Agency-client ///////////////////////////////////
@import '../../components/agency-client-management/agency-client';
// *. Agency-client ///////////////////////////////////

// *. Reports ///////////////////////////////////
@import '../../components/reports/reports';
// *. Reports ///////////////////////////////////

// *. Templates ///////////////////////////////////
@import '../../components/templates/templates';
// *. Templates ///////////////////////////////////

// *. Growth Hub ///////////////////////////////////
@import '../../components/growth-hub/growth-hub';
// *. Growth Hub ///////////////////////////////////

// *. Mailbox Emails ///////////////////////////////////
@import '../../components/mailbox-emails/mailbox-emails.scss';
// *. Mailbox Emails ///////////////////////////////////

// *. Email Warmup ///////////////////////////////////
@import '../../components/email-warmup/email-warmup';
// *. Email Warmup ///////////////////////////////////

// *. Unified Inbox ///////////////////////////////////
@import '../../components/unified-inbox/unified-inbox.scss';
// *. Unified Inbox ///////////////////////////////////

// Native Integrations //////////////////////////////////////
@import '../../components/settings/components/integrations/integrations.scss';
// Native Integrations /////////////////////////////////////

// Api Token //////////////////////////////////////
@import '../../components/settings/components/api-tokens/api-token';
// Api Token /////////////////////////////////////

// **. Global Components ///////////////////////////////////
// Editor
@import '../../shared/editor/editor';

//Organisms
@import '../css/scss/organisms/organisms';

//Atoms
@import '../css/scss/atoms/atoms';

//Molecules
@import '../css/scss/molecules/molecules';

// 404 Page
@import '../../shared/components/error-404/error-404';
// **. Global Components ///////////////////////////////////

// Password validation checklist
@import '../../shared/components/password-validation-checklist/password-validation-checklist.scss';

// Summary Gadget Atom
@import '../../shared/design-system/components/atoms/summary-gadget/summary-gadget';

// Icon Text
@import '../../shared/design-system/components/atoms/icon-text/icon-text';

// Notification
@import '../../shared/design-system/components/atoms/notification/notification';

// Profile-dropdown
@import '../../shared/design-system/components/atoms/profile-dropdown/profile-dropdown';

// Onboarding intro modal
@import '../../shared/components/onboarding-modal/onboarding-modal';

// Overlay Unsubscribe tag
@import '../../shared/components/overlay-unsubscribe-tag/overlay-unsubscribe';

// Connect email account options
@import '../../shared/components/connect-email-account-options/connect-email-account-options.scss';

// Connect email account modal
@import '../../shared/components/connect-email-account-modal/connect-email-account-modal';

// New SideNavbar
@import '../../components/home/components/sidenavbar/sidenavbar';

//  Header
@import '../../components/home/components/header/header';

// Area Chart
@import '../../shared/design-system/components/atoms/charts/area-chart';

// Modal
@import '../../shared/design-system/components/atoms/modal/modal';

// Add Contact Button
@import '../../components/sequence/shared/modals/add-contact-button/add-contact-button';

// add-prospect-form
@import '../../shared/components/add-prospect-form/add-prospect-form';

// Confirmation Modal
@import '../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal';

// Avatar with name
@import './scss/avatarWithName';

// Block Page
@import '../../shared/components/block-page/block-page';

// Logo
@import '../../shared/components/logo/logo';
@import '../../shared/components/logo-secondary/logo-secondary';

// Modify Subscription
@import '../../components/settings/components/billing-subscription/components/modify-subscription/modify-subscriptions';

// Modify Lead Finder Subscription
@import '../../components/settings/components/billing-subscription/components/modify-lead-finder-subscription/modify-lead-finder-subscriptions';

// Prospect Filter
@import '../../components/prospect/components/prospect-list/components/prospects-filters/prospects-filters-modal/prospects-filters-modal';
@import '../../shared/design-system/components/molecules/prospects-filter/prospects-filter';

// All Features List Modal
@import '../../shared/design-system/components/molecules/all-features-list-modal/all-features-list-modal';

// Attachment Card
@import '../../shared/components/attachment/attachment';

// Email Modal
@import '../../components/sequence/shared/modals/email-modal/email-modal';

// Ai Variant Skeleton Modal
@import '../../components/sequence/shared/modals/email-modal/components/ai-variant-skeletons/ai-variant-skeleton';

// Pause Prospect Modal
@import '../../shared/components/pause-prospect-modal/pause-prospect-modal';

// Helpscout Beacon Button
@import '../../shared/components/helpscout-button/helpscout-button';

//Cello Refferral Button
@import '../../shared/components/referral-button/referral-button';

// Email Preview Modal
@import '../../shared/components/email-preview/email-preview';
@import '../../shared/components/email-preview-modal/email-preview-modal';

// Email Account Setup Score
@import '../../shared/components/email-account-setup-score/email-account-setup-score';
@import '../../shared/components/email-account-setup-score/components/factor-details-modal';
@import '../../shared/components/email-account-setup-score/components/factor-copy-button';
@import '../../shared/components/sequence-setup-score/sequence-setup-score';
@import '../../shared/components/email-writing-score/email-writing-score';
@import '../../shared/components/score-factor-detail-item/score-factor-detail-item';
@import '../../shared/components/email-account-health-score/email-account-health-score';
@import '../../shared/components/data-table/data-table';

// Agency Portal
@import '../../components/agency-portal/agency-portal';

// Testimonials Carousel
@import '../../shared/components/testimonials-carousel/testimonials-carousel';

// Verify Email Modal
@import '../../shared/components/verify-email-modal/verify-email-modal';

// Team Filter
@import '../../shared/components/teams-filter/teams-filter';

// React Perfect Scrollbar
@import '../../../node_modules/react-perfect-scrollbar/dist/css/styles.css';

// Date Filter
@import '../../shared/components/date-filter/date-filter';

// Leads
@import '../../components/leads/_leads';

// Get Contact Info Modal
@import '../../components/prospect/components/prospect-list/components/modals/get-contact-info-modal/get-contact-info-modal';

// save filter modal
@import '../../components/leads/components/modal/save-filter-modal/save-filter-modal';

// Date Filter
@import '../../shared/components/date-filter/date-filter';

// Map Fields
@import '../../components/sequence/components/sequence-single-content/components/sequence-single-contacts/components/map-fields/map-fields';

// Reward EV Credits
@import '../../shared/components/reward-ev-credits/reward-ev-credits';
// Custom Email Field For Prospect

@import '../../shared/design-system/components/atoms/prospect-contact-field/prospect-contact-field';

// Form For Prospect
@import '../../shared/design-system/components/organisms/form/form';

// Billing & Subscription Modify Subscription Emails only Annual
@import '../../components/settings/components/billing-subscription/components/lead-finder-plans/components/lead-finder-plan-card/lead-finder-plan-card';

// No Search Result
@import '../../components/leads/components/no-search-result/no-search-result';

// Keyword Search Input
@import '../../components/leads/components/keyword-search-input/keyword-search-input';

// *. Tasks ///////////////////////////////////
@import '../../components/tasks/tasks';

// Input Dropdown
@import '../../shared/components/input-dropdown/input-dropdown';

// Spintax Dropdwon
@import '../../shared/components/spintax-dropdown/spintax-dropdown';

// Modal (New)
@import '../../shared/design-system/ui/modal/modal';

// Schedule Reply Modal
@import '../../shared/components/schedule-reply-modal/schedule-reply-modal';

// Tags Dropdown
@import '../../shared/components/tags-dropdown/tags-dropdown';

// Loader Bar
@import '../../shared/loading-bar/loading-bar';

// Table
@import '../../shared/design-system/ui/table/table';

// Checkbox
@import '../../shared/design-system/ui/checkbox/checkbox';

// Checkbox
@import '../../shared/design-system/components/molecules/edit-table-columns-modal/edit-table-columns-modal';

// Modal (New)
@import '../../shared/design-system/ui/modal/modal';

// Collapsible Search Input
@import '../../shared/design-system/components/atoms/collapsible-search-input/collapsible-search-input';

// Prospect Filters Modal
@import '../../components/prospect/components/prospect-filters-modal/prospect-filters-modal';

// Loader Bar
@import '../../shared/design-system/components/molecules/plan-cycle-switch/plan-cycle-switch';

// Import Flow Modal
@import '../../components/sequence/components/sequence-single-content/components/sequence-single-contacts/components/import-contacts-modal/import-contacts-modal';

// Add Remove Tags
@import '../../shared/components/add-remove-tag/add-remove-tag';

// Multi Chip Input
@import '../../shared/components/multi-chip-input/multi-chip-input';

// Download Usage Report
@import '../../shared/components/download-usage-report/download-usage-report';
// Async Progress Bar
@import '../../shared/components/async-progress-bar/async-progress-bar';

// Sequence Progress Card
@import '../../shared/components/sequence-progress-card/progress-card';

// Sequence Email Account States
@import '../../components/sequence/components/sequence-list-content/components/sequence-list-table/components/email-acc-states-overlay-card/email-acc-states-overlay-card';

// **. Misc ///////////////////////////////////
.pointer {
  cursor: pointer !important;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-underline-hover:hover {
  text-decoration: underline;
}
.text-decoration-uppercase {
  text-decoration: uppercase !important;
}

.text-decoration-uppercase {
  text-decoration: uppercase !important;
}

body {
  overflow-y: hidden;

  &.sb-show-main.sb-main-fullscreen {
    overflow-y: auto;
  }
}

// Styling for headway badge
.HW_badge.HW_visible {
  background: $red-21;
}

.HW_badge.HW_softHidden {
  opacity: 0 !important;
}

.preheader-popover {
  .tooltip-inner {
    width: 100%;
    max-width: 100%;
    text-align: left;
  }
}

// **. Misc ///////////////////////////////////
.form-input-error-caption {
  font-size: 0.625rem;
}

.spinner {
  &--blue {
    border-color: $blue-11 !important;
    border-right-color: transparent !important;
  }
}

.export-btn {
  color: $blue-11 !important;
  border-color: $blue-11 !important;
}

.no-result-empty-list {
  height: calc(100vh - 11.25rem);

  .empty-list__inner-vertical {
    position: static;
    top: 0;
    left: 0;
    transform: none;
    height: 100%;

    .empty-list__photo-vertical {
      height: 10rem;

      img {
        width: 100%;
        height: 100%;
        user-select: none;
      }
    }

    .empty-list__content-vertical {
      padding: 0 1.875rem;
      width: 100%;
      height: auto;
      margin-top: 2.5rem;

      .empty-list__title-vertical {
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        margin-bottom: 0.75rem;
      }

      .empty-list__body-vertical {
        width: 100%;
        font-size: 1rem;
        color: $gray-11;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
}

.subject-cell {
  max-width: 310px;

  div {
    max-width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.sequence-name {
  max-width: 310px;

  div {
    max-width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.action-btn--refresh,
.action-btn--export,
.action-btn--delete {
  color: $gray-15;
  font-weight: $font-weight-medium !important;
  border: none !important;
  padding: 0 !important;
  width: 5rem !important;
  min-width: 5rem !important;
  height: 1.25rem !important;
  transition: all 0.2s ease;

  &:hover {
    color: $blue-11 !important;

    i::before {
      color: $blue-11 !important;
    }
  }

  &:focus {
    box-shadow: none !important;
  }
}

.action-btn--export {
  margin-left: 1.25rem;
  .export-loader {
    height: 20px;
    width: 20px;
  }

  &.icon-only {
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem !important;
    min-width: 2rem !important;
    height: 2rem !important;
    padding: 0.3125rem !important;

    .icon-left {
      margin: 0;
      font-size: 1.25rem;
      color: #6b7280;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      background-color: #dbeafe !important;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  &__mr-20 {
    margin-right: 1.25rem;
  }
}

.action-btn--delete {
  width: max-content !important;
  min-width: max-content !important;
}

.beta-badge {
  width: 2.5rem;
  height: 1.125rem;
  background: $orange-17;
  border-radius: 6.25rem;

  span {
    line-height: 0.875rem;
    color: $gray-1;
  }
}

.faq-badge {
  width: 8.063rem;
  height: 1.625rem;
  color: $gray-15;
  border: 1px solid $blue-12;
  border-radius: 1.25rem;
  transition: 0.5s;

  span {
    line-height: 1.25rem;
    font-size: 0.75rem;
  }
}

.faq-badge:hover {
  border: 1px solid $blue-11;
  color: $blue-11;
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-default {
  cursor: default !important;
}

// Defining color variable to override skeleton styles
.react-loading-skeleton {
  --highlight-color: #ebebeb !important;
}

.page-header-loader {
  padding: 1.5rem 1.875rem;

  div {
    gap: 1.25rem;
  }
}

.table-loader {
  .table-header-loader {
    padding: 0 30px;
    height: 40px;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
  }

  .table-row-loader {
    padding: 0 30px;
    height: 64px;
    border: 1px solid #e8e8e8;
  }
}

.is-manual {
  left: 70px !important;
}

.report-seq-filter {
  margin-right: 1.25rem;
  background-color: $gray-19 !important;
}

.sequence-report-select-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 10.1875rem;
  height: 2rem;
  padding: 0.5rem 0.75rem;

  font-size: 0.75rem;
  font-weight: $font-weight-medium;
  color: $blue-15;

  background-color: $gray-1;
  border: 0.0625rem solid $gray-26;
  border-radius: 0.25rem;

  cursor: pointer;

  svg {
    color: $gray-11;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    i {
      color: $gray-12;
    }
  }
}

.sequence-report-select {
  padding: 2px 0 0 0 !important;
  height: 2rem;

  .transfer-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      /* Chrome, Safari and Opera */
    }
  }

  .transfer-footer {
    border-top: 1px solid $gray-13;
  }

  .transfer-list {
    li {
      height: 40px;
      line-height: 20px !important;
      padding: 10px 16px !important;

      .checkbox-wrapper,
      .checkbox-wrapper label {
        width: 1rem;
        height: 1rem;
        line-height: 20px;
      }

      .option-content {
        display: flex;
        align-items: center;
        height: 20px;
        line-height: 20px;
        width: calc(100% - 24px);
      }
    }

    .disabled {
      pointer-events: none;

      .bs-checkbox-wrapper.bs-checkbox-wrapper-disabled
        .bs-checkbox.bs-checkbox-disabled
        .bs-checkbox-inner {
        opacity: 0.5;
        background-color: $blue-11;
        border-width: 0px;

        &::after {
          background-color: $blue-11;
        }
      }

      .option-content {
        opacity: 0.5;
      }
    }
  }

  .sequence-name-item {
    font-size: 0.875rem;
    font-family: $font-family-roboto;
    font-weight: $font-weight-normal;
    line-height: 1.25rem;
    color: $blue-15;
    margin-top: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.gray-secondary-btn {
  min-width: 6.25rem !important;
  height: 2rem !important;
  border: 0.0625rem solid #e5e7eb !important;
  font-weight: 500 !important;
  line-height: 1.25rem !important;
  transition: all 0.2s ease !important;
  color: #6b7280 !important;
  &:focus-visible {
    outline: none;
  }
  &:hover,
  &:active,
  &:focus {
    color: #6b7280 !important;
    background-color: #f3f4f6 !important;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
    }
  }
}

.prospect-filter-bar-loader {
  gap: 1.875rem;
  span {
    display: flex;
    height: 1.9125rem;
  }
}

.copy-button-icon {
  transition: all 0.2s ease;
  &:hover {
    color: $blue-11;
  }
}

.click-to-copy-tooltip {
  .tooltip-inner {
    width: 5.9375rem;
    max-width: 5.9375rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
